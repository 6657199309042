@media only screen and (max-width: 600px) {
	.contact-form {
		width: 100%;
	}
}

@media only screen and (max-width: 850px) {
	.contact-form {
		width: 65%;
	}
}

@media only screen and (min-width: 851px) {
	.contact-form {
		width: 50%;
	}
}
.bgstyle {
  /* background: linear-gradient(136deg, #1ad7c0, #8E44AD, #ff9b11, #1F3A93, #ddd8c6, #000000, #000000);  */
  background: linear-gradient(136deg, #173962, #1d5482, #1f70a2, #1f8fc1, #1daee0);

  background-size: 1200% 1200%;
  -webkit-animation: AnimationName 70s ease infinite;
  -moz-animation: AnimationName 70s ease infinite;
  -o-animation: AnimationName 70s ease infinite;
  animation: AnimationName 70s ease infinite;
  height: 100%;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@-o-keyframes AnimationName {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

.socialicon {
  color: black;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
}

.socialiconhover {
  color: blue;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
}
