.holder {
    width: 50%;
    margin: 50px auto;
    padding: 20px;
     }

  .uul {
    list-style-type: none;
    
  }
  .ull
  {
      overflow: hidden;
      margin: 0 0 20px;
      border-bottom: 1px solid #ccc;
      padding: 0 0 20px;
      &:last-child {
        margin: 0;
      }
    }
  
    @media only screen and (max-width: 850px) {
    .holder {
      width: 100%;
      margin: 50px auto;
      padding: 20px;
       }
  }
  .navUl{
    height:350px; 
    width:100%;
  }
  .navUls{
    overflow:hidden;
     overflow-y:scroll;
    }

  .imgIcon {
    float: left;
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 10px 0 0;
    margin-left: 15px;
  }
  .Field {
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
 }

  .inputContainer i {
    position: absolute;
 }

 .icon {
  padding: 15px;
  color: grey;
  width: 70px;
  text-align: left;
}
 .inputContainer {
    width: 100%;
    margin-bottom: 10px;
 }

  .spanController {
    display: block;
    width: 100%;
    text-align: left;
    font-weight: bolder;
    &.phone {
      font-weight: normal;
      font-style: italic;
      color: #999;
    }
  }