@media only screen and (max-width: 850px) {
	.imageCss {
		visibility: visible;
        left: 50%;
        position: relative ;
        margin-left: -100px;
        top: 10px;
        max-height: 200px;
        max-width: 200px;
        margin-bottom: 35px;
        margin-top: -50px;
    }
    .noImageCss {
       display: none;
    }
    .imageLeft
    {
        left: 50%;
        float: left;
        position: relative;
        margin-left: -128px;
        top: 10px;
        max-height: 256px;
        max-width: 256px;
        margin-bottom: 35px;
        margin-top: -50px;
    }
}

@media only screen and (min-width: 851px) {
	.imageCss {
		display: none;
	}
    .noImageCss {
        visibility: visible;
    }
}

.whiteText{
font:"white"

}